<!--模拟登录-->
<template>
    <div class="mock-login">
        <div class="mock-content">
            <h2>模拟登录</h2>
            <div class="item">
                <label><i class="iconfont iconkehu1"></i>账号：</label>
                <el-input v-model="UserName" readonly></el-input>
            </div>
            <div class="item">
                <label><i class="iconfont iconmima"></i>密码：</label>
                <el-input v-model="Password" type="password" placeholder="请输入密码"></el-input>
            </div>
            <div class="item">
                <label><i class="iconfont iconkehu1"></i>模拟账号：</label>
                <el-input v-model="SimulatedUserName" placeholder="请输入模拟账号"></el-input>
            </div>
            <div class="btns">
                <el-button
                    class="btn btn1"
                    @click="handleLogin"
                >
                    登录
                </el-button>
                <el-button class="btn btn2" @click="handleClose">
                    取消
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import currencyFun from '@/assets/js/currencyFun';

export default {
    name: 'mock-login',
    data() {
        return {
            UserName: '',
            Password: '',
            SimulatedUserName: '',
        };
    },
    created() {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            const userId = this.$takeTokenParameters('Uid');
            this.$axios
                .get(`/interfaceApi/basicplatform/users/${userId}`)
                .then(data => {
                    this.UserName = data.Login_Name;
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 模拟登录
        handleLogin() {
            const qs = require('qs');
            if (this.Password.trim() === '') {
                this.$error.warning('请输入当前账户密码');
                return;
            } if (this.SimulatedUserName.trim() === '') {
                this.$error.warning('请输入模拟账号');
                return;
            }
            this.$axios.post('/interfaceApi/login/simulated', qs.stringify({
                loginName: this.UserName,
                password: this.Password,
                simulatedAccount: this.SimulatedUserName,
            }), {
                headers: {
                    ContentType: 'application/x-www-form-urlencoded',
                },
            }).then(async res => {
                sessionStorage.setItem('token', `${res.token_type} ${res.access_token}`);
                sessionStorage.setItem('refreshToken', `${res.token_type} ${res.refresh_token}`);
                sessionStorage.setItem('loginType', 'mock');
                // 保存用户信息
                await currencyFun.getUserInfo();
                window.location.href = '/';
            }).catch(error => {
                this.$message.error(error.error_description);
            });
        },
        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .mock-login
        width 4rem;
        padding 0.07rem;
        background: rgba(255, 255, 255, 0.3);
        position relative
        z-index: 99;
        .mock-content
            width 100%;
            height 100%;
            background #fff;
            padding 0.3rem;
            h2
                font-size 0.2rem;
                color #333333;
                font-weight 500;
                margin-bottom 0.15rem;
            .item
                display flex;
                align-items center;
                margin 0.2rem 0;
                label
                    display flex
                    align-items center;
                    width 1.2rem;
                    font-size 0.16rem;
                    color #666666;
                    i
                        font-size 0.16rem;
                        margin-right 0.1rem;
                .el-input,/deep/ .el-input
                    flex 1;
            .btns
                margin-top 0.4rem
                display flex;
                justify-content space-around;
                align-items center;
                .btn
                    display flex
                    justify-content center;
                    align-items center;
                    width: 1.4rem;
                    height: 0.4rem;
                    border-radius: 1px;
                    border: 1px solid #1577D2;
                    color #1577D2;
                    font-size 0.16rem;
                    &.btn1
                        background: #1577D2;
                        border-radius: 1px;
                        color #fff;
</style>
